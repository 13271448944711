import React from 'react'
import { EditText } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {
    CREATE_STUDENT_ANSWERS,
    SET_HOMEWORK_CONFIG_ASSIGNMENT,
} from "../../../redux/actions/configurator-actions";

import {FiCheck} from "react-icons/fi";

import Player from "../Player";
import Image from "../Image";
import Loading from "../../components/Loading";
import {SET_PAGE_BACKGROUND_REQUESTED} from "../../../redux/actions/common-actions";

class FillTask extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            readOnly: false,
            previewData: [],
            backGroundStyle: {},
            playing: false,
            showAssignment: false

        };
    }

    componentCleanup = () => {
        this.props.setHomeworkConfigAssignment([])
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.componentCleanup);
        if (this.props.assignment === null || this.props.assignment === undefined) {
            this.props.setHomeworkConfigAssignment([])
        }
        if(this.props.background.type !== 'image' && this.props.assignment.length > 0){
            this.setState({showAssignment: true})
            this.props.setPageBackground({
                background: this.props.background.source
            })
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.background.source !== this.props.background.source && prevProps.assignment !== this.props.assignment){
            if(this.props.background.type !== 'image'){
                this.setState({showAssignment: true})
                this.props.setPageBackground({
                    background: this.props.background.source
                })
            }
        }
    }

    componentWillUnmount() {
        this.componentCleanup()
    }

    handleChange = (value, rowIndex, elementIndex) => {
        let results = [...this.props.assignment]
        results[rowIndex][elementIndex].studentAnswer = this.normalizeString(value)
        results[rowIndex][elementIndex].result = value

        if (this.normalizeString(value) !== this.normalizeString(results[rowIndex][elementIndex].text)) {
            results[rowIndex][elementIndex].isCorrect = false
        } else if (this.normalizeString(value) === this.normalizeString(results[rowIndex][elementIndex].text)) {
            results[rowIndex][elementIndex].isCorrect = true
        }

        this.props.setHomeworkConfigAssignment(results)
    }

    normalizeString = (string) => {
        return string.toLowerCase().trim().replace(/  +/g, ' ')
    }

    submitAnswers = (e) => {
        e.preventDefault();

        let all = 0
        let correct = 0
        let tempAssignment = JSON.parse(JSON.stringify(this.props.assignment))

        let results = tempAssignment.map(function(row){
            return row.map((element) => {
                if (element.isAnswer){
                    element.result = element.text
                    element.color = 'teal'
                    all += 1
                }
                if (element.isCorrect) {
                    correct += 1
                }
                return element
            })
        })

        let studentAnswers = {
            homework: {uid: this.props.homeworkId, type: this.props.type},
            answer: { type: this.props.type, content: results },
            allAssignments: all,
            correctAnswer: correct,
            student: {uid: this.props.userInfo.uid}
        }

        this.props.createStudentAnswer(studentAnswers)
    }



    render() {

        return (
            <React.Fragment>
                <div className={[this.props.background.type === 'image' && this.state.showAssignment ? 'relative' : '',].join('')}>
                    {this.state.showAssignment ? (
                        <div className={[this.props.background.type === 'image' ? 'absolute container' : 'flex-1'].join('')}>
                            <div
                                className="justify-between pt-20 px-4 flex flex-col"
                            >
                                {this.props.type === 'fill' && this.props.withMedia ? (
                                    <>
                                        { this.props.isVideo ? (
                                            <Player />
                                        ) : null }

                                        { this.props.isImage ? (
                                            <Image />
                                        ) : null }
                                    </>
                                ) : null}
                            </div>

                            {this.props.withMedia ? (<div className="divider w-screen xs:max-w-laptop" />) : null}

                            {this.props.taskTitle && (
                                <div className={"px-4 font-bold pb-2"}>{this.props.taskTitle}</div>
                            )}

                            <div className={"mt-5 pb-10 flex flex-col text-justify px-4 space-y-4"}>
                                {this.props.assignment.map((row, rowIndex) => {
                                    if(row.length > 0) {
                                        const useInline = row.findIndex(element => element.isAnswer && (element.style === 'CODE' || element.style === undefined)) > 0
                                        return (
                                            <div
                                                key={`row-${rowIndex}`}
                                                className={`break-words ${useInline ? 'inline-block' : 'flex flex-col space-y-2'}`}
                                                style={{marginTop: 0}}
                                            >
                                                {row.map((element, elementIndex) => {
                                                    if(element.style === 'TRUE-FALSE' && element.isAnswer){
                                                        return (
                                                            <select
                                                                className={"w-40 select select-bordered select-sm"}
                                                                key={`element-${rowIndex}-${elementIndex}`}
                                                                name={`element-${rowIndex}-${elementIndex}`}
                                                                defaultValue={' '}
                                                                onChange={event => {
                                                                    this.handleChange(event.target.value, rowIndex, elementIndex)
                                                                }}
                                                            >
                                                                <option value={' '} disabled selected>Pick the answer</option>
                                                                <option value={'true'}>True</option>
                                                                <option value={'false'}>False</option>
                                                            </select>
                                                        )
                                                    } else if (element.isAnswer) {
                                                        return <EditText
                                                            key={`element-${rowIndex}-${elementIndex}`}
                                                            name={`element-${rowIndex}-${elementIndex}`}
                                                            type="text"
                                                            style={{
                                                                width: element.result.length > 2 ? element.result.length.toString() + 'ch' : '3ch',
                                                                color: element.result !== '' ? element.color : 'teal',
                                                                opacity: 0.8,
                                                                border: "none",
                                                                paddingLeft: 3,
                                                                paddingRight: 0
                                                            }}
                                                            placeholder={this.props.type === 'put' ? element.correct : 'type here'}
                                                            value={element.result}
                                                            onChange={event => {
                                                                this.handleChange(event.target.value, rowIndex, elementIndex)
                                                            }}
                                                            inline
                                                        />
                                                    } else {
                                                        return useInline ? element.text : <div> {element.text} </div>
                                                    }
                                                })}
                                            </div>
                                        )
                                    } else {
                                        return <div key={`row-${rowIndex}`} className={"whitespace-pre-line py-1"} />
                                    }

                                })}
                            </div>

                            <div className={"flex flex-col items-center pb-3"}>
                                <button
                                    disabled={this.props.loadingCreateStudentAnswer}
                                    className={`py-2 px-4 btn-primary btn btn-md rounded-full btn-wide shadow-brand-button`}
                                    onClick={this.submitAnswers}
                                >
                                    {this.props.loadingCreateStudentAnswer ? (
                                        <span className="loading loading-spinner loading-xs"></span>
                                    ) : (
                                        <FiCheck className="w-6 h-6 stroke-current mr-1"/>
                                    )}
                                    <span className="font-medium">Submit your answer</span>
                                </button>
                                <p className="text-xs opacity-60 mt-3">You have only one try</p>
                            </div>
                        </div>
                    ) : (
                        <div className="flex justify-center items-center h-screen bg-brandYellow absolute inset-0">
                            <Loading isFullScreen={true}/>
                        </div>
                    )}

                    {this.props.background.type === 'image' ? (
                        <img
                            className="h-screen object-cover xs:max-w-laptop"
                            alt="background"
                            src={this.props.background.source}
                            onLoad={() => {
                                this.setState({showAssignment: true})
                            }}

                        />
                    ) : null}

                </div>

            </React.Fragment>
        );
    }
}

FillTask.propTypes = {
    userInfo: PropTypes.object,
    type: PropTypes.string,
    withMedia: PropTypes.bool,
    isVideo: PropTypes.bool,
    isImage: PropTypes.bool,
    taskTitle: PropTypes.string,
    assignment: PropTypes.array,
    background: PropTypes.object,
    setHomeworkConfigAssignment: PropTypes.func,
    homeworkId: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    createStudentAnswer: PropTypes.func,
    studentAnswers: PropTypes.object,
    setPageBackground: PropTypes.func,
    loadingCreateStudentAnswer: PropTypes.bool,
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    type: state.config.type,
    withMedia: state.config.withMedia,
    isVideo: state.config.isVideo,
    isImage: state.config.isImage,
    taskTitle: state.config.taskTitle,
    assignment: state.config.assignment,
    background: state.config.background,
    homeworkId: state.config.homeworkId,
    isLoggedIn: state.auth.isLoggedIn,
    studentAnswers: state.config.studentAnswers,
    loadingCreateStudentAnswer: state.config.loadingCreateStudentAnswer
})

const mapDispatchToProps = (dispatch) => ({
    setHomeworkConfigAssignment: (assignment) => dispatch({ type: SET_HOMEWORK_CONFIG_ASSIGNMENT, payload: assignment }),
    createStudentAnswer: (answer) => dispatch({ type: CREATE_STUDENT_ANSWERS, payload: answer }),
    setPageBackground: (background) => dispatch({ type: SET_PAGE_BACKGROUND_REQUESTED, payload: background })
})

export default connect(mapStateToProps, mapDispatchToProps)(FillTask)